<template>
  <div class="prospect__email" :class="{ 'prospect__email--pinned': email.pinned }">
    <div class="email__header">
      <pinned-badge v-if="email.pinned" />
      <div class="email__header-title">
        <div
          class="email__title"
          v-tooltip.top="{
            value: title,
            pt: {
              root: 'large-tooltip'
            }
          }"
        >
          {{ title }}
        </div>
        <div class="email__date">{{ formatDate(email.date) }}</div>
      </div>
    </div>
    <div
      class="email__metadata"
      v-tooltip.top="{
        value: metadataTooltipText,
        pt: {
          root: 'large-tooltip'
        }
      }"
    >
      <div class="email__metadata-from">{{ email.fromName }}</div>
      <div class="email__metadata-to">
        {{ toEmailsString }}
      </div>
    </div>
    <div class="email__body" v-html="firstPart"></div>
    <div v-if="showSeeMore" @click="toggleSeeMore" class="email__see-more">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div class="email__body" v-if="showReplies" v-html="remainingPart"></div>
  </div>
</template>

<script setup>
import { formatDate } from '../helpers/date'
import PinnedBadge from './PinnedBadge.vue'

import { computed, onMounted, ref } from 'vue'

const props = defineProps({
  email: {
    type: Object,
    required: true
  }
})

const firstPart = ref('')
const remainingPart = ref('')

const showReplies = ref(false)

function toggleSeeMore() {
  showReplies.value = !showReplies.value
}

const showSeeMore = ref(false)

const toEmailsString = computed(() => {
  return (
    'To ' +
    props.email.toEmails
      .concat(props.email.ccToEmails)
      .concat(props.email.bccToEmails)
      .map((item) => item.name)
      .join(', ')
  )
})

const metadataTooltipText = computed(() => {
  const toString =
    'To: ' + props.email.toEmails.map((item) => `${item.name || ''} <${item.email}>`).join(', ')
  let ccString = ''
  if (props.email.ccToEmails.length) {
    ccString =
      'Cc: ' + props.email.ccToEmails.map((item) => `${item.name || ''} <${item.email}>`).join(', ')
  }
  let bccString = ''
  if (props.email.bccToEmails.length) {
    bccString =
      'Bcc: ' +
      props.email.bccToEmails.map((item) => `${item.name || ''} <${item.email}>`).join(', ')
  }

  let string = `From: ${props.email.fromName} <${props.email.fromEmail}>
  ${toString}`
  if (ccString) {
    string += `\n${ccString}`
  }
  if (bccString) {
    string += `\n${bccString}`
  }

  return string
})

const title = computed(() => {
  return props.email.title
})

onMounted(() => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(props.email.body, 'text/html')
  const blockquoteElement = doc.querySelector('blockquote')

  if (blockquoteElement) {
    // Get the HTML content before the blockquote
    const allContent = doc.body.innerHTML
    const indexOfBlockquote = allContent.indexOf(blockquoteElement.outerHTML)
    let beforeBlockquote = allContent.substring(0, indexOfBlockquote)

    // Find the line before the blockquote
    const previousSibling = blockquoteElement.previousSibling
    if (previousSibling) {
      if (previousSibling.previousSibling) {
        beforeBlockquote = allContent.substring(
          0,
          allContent.indexOf(previousSibling.previousSibling.outerHTML)
        )
      } else {
        beforeBlockquote = allContent.substring(0, allContent.indexOf(previousSibling.outerHTML))
      }
    }

    // Get the HTML content of the blockquote and everything after it
    const remainingContent = allContent.substring(beforeBlockquote.length)

    // Update firstPart and remainingPart
    firstPart.value = beforeBlockquote.trim()
    remainingPart.value = remainingContent.trim()
    showSeeMore.value = true
  } else {
    firstPart.value = doc.body.innerHTML
    remainingPart.value = ''
    showSeeMore.value = false
  }
})
</script>

<style lang="scss">
.large-tooltip {
  max-width: 400px;
}
.prospect__email {
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 16px;
  overflow: auto;
  &--pinned {
    border: 1px solid #ababab;
  }

  .email__metadata {
    display: flex;
    align-self: self-start;
    flex-direction: column;
    margin-bottom: 16px;

    .email__metadata-from {
      font-weight: 600;
    }
    .email__metadata-to {
      font-size: 12px;
      color: #667085;
    }
  }
  .email__header {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    .email__header-title {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 4px;
    }

    .email__title {
      align-self: self-start;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .email__date {
      font-size: 12px;
      color: #667085;
      flex-shrink: 0;
    }
  }
  .email__see-more {
    margin-top: 4px;
    background-color: #e8eaed;
    border-radius: 6px;
    display: grid;
    column-gap: 3px;
    grid-template-columns: repeat(3, 1fr);
    align-self: flex-start;
    padding: 6px;
    align-items: center;

    .dot {
      width: 4px;
      height: 4px;
      background-color: #787878;
      border-radius: 50%;
    }

    &:hover {
      cursor: pointer;
      background-color: #e0e2e4;
    }
  }
}
</style>
