import { ref, computed, watch, onMounted } from 'vue'
import { useListStore } from '@/stores/listStore'
import { usePhoneStore } from '@/stores/phone'

export function useImport(emit) {
  const listStore = useListStore()
  const phoneStore = usePhoneStore()
  const searchTerm = ref('')
  const showDropdown = ref(false)
  const inputField = ref(null)

  const listLoading = computed(() => listStore.listLoading)

  function focusInput() {
    if (phoneStore.isDialingOrCalling) {
      return
    }
    inputField.value.focus()
    showDropdown.value = true
  }

  function openDropdown() {
    showDropdown.value = true
  }

  const filteredList = computed(() => {
    if (!searchTerm.value.trim()) {
      return listStore.importLists
    }

    const searchWords = searchTerm.value.toLowerCase().split(/\s+/)
    return listStore.importLists.filter((item) =>
      searchWords.every((word) => item.text.toLowerCase().includes(word))
    )
  })

  const importLists = computed(() => {
    return listStore.importLists
  })

  watch(
    () => searchTerm.value,
    () => {
      highlightedIndex.value = 0
      scrollToHighlightedItem()
    }
  )

  const selectValue = async (value) => {
    try {
      searchTerm.value = ''
      showDropdown.value = false

      inputField.value.blur()

      if (value.id) {
        emit('import-list', value)
      }
    } catch (error) {
      console.error('error while loading new list', error)
    }
  }

  onMounted(() => {
    listStore.fetchLists()
  })

  const importListDropdown = ref(null)

  function closeDropdown() {
    showDropdown.value = false
    searchTerm.value = ''
    emit('close')
  }

  const highlightedIndex = ref(-1)

  const focusNextItem = () => {
    if (highlightedIndex.value < filteredList.value.length - 1) {
      highlightedIndex.value++
      scrollToHighlightedItem()
    }
  }

  const focusPreviousItem = () => {
    if (highlightedIndex.value > 0) {
      highlightedIndex.value--
      scrollToHighlightedItem()
    }
  }

  const selectHighlightedItem = () => {
    if (highlightedIndex.value >= 0 && highlightedIndex.value < filteredList.value.length) {
      selectValue(filteredList.value[highlightedIndex.value])
    }
  }

  const scrollToHighlightedItem = () => {
    const dropdown = importListDropdown.value
    if (dropdown) {
      const highlightedItem = dropdown.children[highlightedIndex.value]
      if (highlightedItem) {
        highlightedItem.scrollIntoView({ block: 'nearest' })
      }
    }
  }

  return {
    searchTerm,
    showDropdown,
    openDropdown,
    inputField,
    focusInput,
    filteredList,
    selectValue,
    importListDropdown,
    closeDropdown,
    highlightedIndex,
    focusNextItem,
    focusPreviousItem,
    selectHighlightedItem,
    scrollToHighlightedItem,
    importLists,
    listLoading
  }
}
