<template>
  <div class="import-list__input-group">
    <input
      type="text"
      ref="inputField"
      v-model="searchTerm"
      @focus="openDropdown"
      @click="openDropdown"
      placeholder="Search list..."
      class="input__text"
      @keydown.down.prevent="focusNextItem"
      @keydown.up.prevent="focusPreviousItem"
      @keydown.enter.prevent="selectHighlightedItem"
      @keydown.esc.prevent="closeDropdown"
      :aria-expanded="showDropdown"
      aria-controls="importListDropdown"
      aria-label="Search list..."
      :disabled="phoneStore.contactLoading || phoneStore.isDialingOrCalling"
    />
    <span class="input__icon" @click="focusInput">
      <i class="pi pi-download"></i>
    </span>
    <Loader
      v-if="phoneStore.contactLoading"
      class="input__loader"
      :size="20"
      :borderWidth="2"
      border-color="#ababab"
    />
    <div
      v-if="showDropdown"
      class="import-list__dropdown-menu"
      ref="importListDropdown"
      role="listbox"
    >
      <div v-if="listLoading" class="empty-state">
        <Loader :size="20" :borderWidth="2" border-color="#ababab" />
      </div>
      <div v-else-if="filteredList.length === 0" class="empty-state">No list</div>
      <div
        v-for="(item, index) in filteredList"
        :key="`${item.source}_${item.id}`"
        @click="selectValue(item)"
        @mouseenter="highlightedIndex = index"
        :class="['dropdown-item', { 'dropdown-item--highlighted': highlightedIndex === index }]"
        role="option"
        :aria-selected="highlightedIndex === index"
      >
        <div class="item__logo-container">
          <img
            class="item__logo"
            v-if="item.logo"
            :src="item.logo"
            :alt="item.logo + 'logo'"
            rel="preload"
          />
          <div v-else class="history-icon-section">
            <HistoryIcon class="history-icon" size="20" />
          </div>
        </div>

        <div v-if="item.type === 'list' || item.type === 'smartList'" class="item-list__text">
          <div class="item-list__task-text">
            {{ item.text }}
          </div>
          <div class="item-list__count" v-if="item.count > -1">
            {{ item.count }} {{ item.count > 1 ? 'contacts' : 'contact' }}
          </div>
        </div>
        <div v-else-if="item.type === 'task'" class="item-list__text">
          <div class="item-list__task-text">
            <ListIcon class="item__task-icon" />{{ item.text }}
          </div>
          <!-- <div class="item-list__count">
            {{ item.count }} {{ item.count > 1 ? 'tasks' : 'task' }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ListIcon from './icons/ListIcon.vue'
import Loader from './Loader.vue'
import HistoryIcon from './icons/HistoryIcon.vue'

import { onClickOutside } from '@vueuse/core'

const emit = defineEmits(['import-list', 'close'])

import { useImport } from '@/composables/useImport'
import { usePhoneStore } from '@/stores/phone'

const phoneStore = usePhoneStore()

const {
  searchTerm,
  showDropdown,
  inputField,
  focusInput,
  filteredList,
  selectValue,
  importListDropdown,
  closeDropdown,
  highlightedIndex,
  focusNextItem,
  focusPreviousItem,
  selectHighlightedItem,
  scrollToHighlightedItem,
  importLists,
  openDropdown,
  listLoading
} = useImport(emit)

onClickOutside(importListDropdown, () => {
  closeDropdown()
})
</script>

<style lang="scss" scoped>
.history-icon-section {
  width: 40px;
  display: flex;
  justify-content: center;
  .history-icon {
    stroke: #787878;
  }
}
.import-list__input-group {
  display: flex;
  width: 200px;
  margin-right: 20px;
  position: relative;
  line-height: normal;
  .input__icon {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 0.5rem;
    background: #0070ff;
    color: #ffffff;
    border-top: 1px solid #0070ff;
    border-left: 1px solid #0070ff;
    border-bottom: 1px solid #0070ff;
    padding: 0.5rem 0.75rem;
    min-width: 2.5rem;
    cursor: pointer;

    &:hover {
      background-color: #006fe6;
      border-color: #006fe6;
    }
  }
  .input__text {
    border-radius: 6px;
    color: #334155;
    background: #ffffff;
    padding: 0.5rem 0.75rem 0.5rem calc(36px + 0.75rem);
    border: 1px solid #cbd5e1;
    outline: none;
    width: 100%;

    box-sizing: border-box;
    transition: border-color 0.3s;
    font-weight: 500;
    &:hover {
      border: 1px solid #0070ff;
    }
  }
  input:disabled {
    background: #f0f0f0;
    color: #a0a0a0;
    border: 1px solid #d1d5db;
  }
  input::placeholder {
    font-weight: 500;
  }
  input:focus {
    border: 1px solid #0070ff;
    outline: 2px solid #cbe2ff;
    &:hover {
      border: 1px solid #0070ff;
    }
  }
  .import-list__dropdown-menu {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #344054;
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    overflow: visible;
    background: #fff;
    z-index: 2;

    width: 400px;
    max-height: 400px;
    overflow-y: auto;
    .dropdown-item {
      padding: 8px;
      cursor: pointer;

      border-bottom: 1px solid #eaecf0;
      display: flex;
      align-items: center;
      padding: 12px 0 12px 24px;
      transition: background 0.027s ease;
      color: #2b2c2e;

      .item__logo-container {
        height: 30px;
        margin-right: 20px;
        display: flex;
        align-items: center;
      }
      .item__logo {
        width: 40px;
      }

      .item__text {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        line-height: 14px;
        flex: 1;
      }

      .item-list__text {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        line-height: 14px;
        flex: 1;
        .item-list__task-text {
          display: flex;
        }
      }

      .item-list__count {
        color: #667085;
        margin-top: 8px;
        font-weight: 600;
        font-size: 12px;
      }

      .item__task-icon {
        margin-right: 5px;
        stroke: #2b2c2e;
      }
    }
    .dropdown-item--highlighted {
      background-color: #f0f0f0;
    }
  }
  .input__loader {
    position: absolute;
    right: 10px;
    top: 7px;
  }
  .empty-state {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
}
</style>
