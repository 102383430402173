<template>
  <div class="progress-bar-container">
    <div class="progress-bar" :style="{ width: `${percentage}%` }"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useListStore } from '@/stores/listStore'

const listStore = useListStore()

const progress = computed(() => listStore.phoneUpdateProgress)

const percentage = computed(() => {
  if (!progress.value) return 0
  return Math.round((progress.value.completed / progress.value.total) * 100)
})
</script>

<style scoped lang="scss">
.progress-bar-container {
  height: 8px;
  background-color: #f2f2f2;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
}

.progress-bar {
  background-color: #313131;
  height: 100%;
  transition: width 0.3s ease;
}
</style>
