<template>
  <div class="datatable--homemade">
    <div class="datatable-wrapper" ref="tableWrapper">
      <div class="datatable-header">
        <div class="row">
          <div class="data-table-header header--checkbox-column">
            <div class="header-icon-placeholder"></div>
          </div>
          <div class="data-table-header header--first-column">
            {{ headers[0].label }}
          </div>
          <div
            v-for="(header, index) in headers.filter(
              (header) => header.id !== 'dialStatus' && !header.hidden && !header.hideInTable
            )"
            :key="index"
            @click="sortBy(header)"
            class="data-table-header"
            :class="{
              'header--fixed-width': header.id !== 'callCount',
              'header--call-count': header.id === 'callCount'
            }"
          >
            <div
              class="header__icon-container"
              :class="{ 'header__icon-container--sortable': header.sortable }"
            >
              {{ header.label }}
              <template v-if="header.sortable">
                <ArrowUpIcon
                  v-if="
                    phoneStore.sortByField?.sortField === header.id &&
                    phoneStore.sortByField?.sortOrder === 1
                  "
                  class="header__icon header__icon--active"
                  size="16"
                />
                <ArrowDownIcon
                  v-else-if="
                    phoneStore.sortByField?.sortField === header.id &&
                    phoneStore.sortByField?.sortOrder === -1
                  "
                  class="header__icon header__icon--active"
                  size="16"
                />
                <ArrowUpIcon v-else class="header__icon" size="16" />
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="datatable-body">
        <DatatableRow
          v-for="row in filteredRows"
          :key="row.id"
          :row="row"
          :headers="headers"
          @menu-click="($event) => rowMenuClicked($event, row)"
          @open-modal="() => openModal(row)"
        />
      </div>
      <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" class="prime-menu">
        <template #itemicon>
          <TrashIcon class="trash-icon" size="20" />
        </template>
      </Menu>
    </div>
  </div>
</template>

<script setup>
import { usePhoneStore } from '@/stores/phone'
import { ref, computed, watch } from 'vue'
import ArrowUpIcon from './icons/ArrowUpIcon.vue'
import ArrowDownIcon from './icons/ArrowDownIcon.vue'
import DatatableRow from './DatatableRow.vue'
import Menu from 'primevue/menu'
import TrashIcon from './icons/TrashIcon.vue'

const props = defineProps({
  rows: {
    type: Array,
    required: true
  },
  headers: {
    type: Array,
    required: true
  }
})

const phoneStore = usePhoneStore()
const filteredRows = computed(() => props.rows.filter((row) => !row.isDeleted))
const emit = defineEmits(['update:rows', 'openRowModal', 'deleteContact', 'sort:rows'])

const menu = ref()
const items = ref([
  {
    label: 'Delete',
    command: deleteContactFromList
  }
])

function deleteContactFromList() {
  emit('deleteContact', selectedRows.value)
}

function sortBy(sortHeader) {
  if (!sortHeader.sortable) {
    return
  }
  if (phoneStore.sortByField?.sortField === sortHeader.id) {
    phoneStore.sortByField.sortOrder = phoneStore.sortByField.sortOrder === 1 ? -1 : 1
  } else {
    phoneStore.sortByField = { sortField: sortHeader.id, sortOrder: 1, sortLabel: sortHeader.label }
  }
  emit('sort:rows')
}

const selectedRows = ref([])

function rowMenuClicked($event, row) {
  selectedRows.value = row
  menu.value.toggle($event)
}

function openModal(row) {
  emit('openRowModal', { data: row })
}

const tableWrapper = ref(null)

function scrollToTop() {
  if (tableWrapper.value) {
    tableWrapper.value.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

// Add this watch
watch(
  () => phoneStore.isDialingOrCalling,
  (newValue) => {
    if (newValue) {
      scrollToTop()
    }
  }
)
</script>

<style lang="scss">
.datatable--homemade {
  width: 100%;
  height: 100%;
  display: flex;

  .datatable-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    overflow-x: auto;
    height: 100%;
  }

  .datatable-header {
    background-color: #fcfcfd;
    position: sticky;
    top: 0;

    .row {
      display: flex;
      min-width: fit-content;
      border-bottom: 1px solid #e2e8f0;
    }
  }

  .datatable-body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .data-table-header {
    color: #667085;
    font-weight: 500;
    font-size: 12px;
    user-select: none;
    padding: 12px;
    display: flex;
    align-items: center;

    &:last-child {
      flex: 1;
    }

    &.header--fixed-width {
      min-width: 195px;
    }

    &.header--call-count {
      min-width: 70px;
    }

    .cell {
      color: #667085;
      font-size: 14px;
      vertical-align: middle;
      padding: 12px;
      overflow: hidden;
      box-sizing: border-box;
    }

    .cell--fixed-width {
      min-width: 195px;
      max-width: 195px;
    }

    &.header--first-column {
      padding-left: 0;
      padding-right: 0;
      min-width: 145px;
    }

    .header__icon-container {
      display: flex;
      align-items: center;

      &--sortable {
        cursor: pointer;
      }

      .header__icon {
        stroke: #dbdbdb;

        &--active {
          stroke: #667085;
        }
      }
    }
  }

  .header--checkbox-column {
    padding: 12px 0 12px 40px;
    min-width: unset;
  }

  .header__icon-container {
    display: flex;
    align-items: center;
    &--sortable {
      cursor: pointer;
    }
    .header__icon {
      stroke: #dbdbdb;
      &--active {
        stroke: #667085;
      }
    }
  }
  .header-icon-placeholder {
    height: 20px;
    width: 20px;
  }
}
</style>
