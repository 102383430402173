<template>
  <div class="prospect__call-log prospect__call-log--active">
    <div class="log__header">
      <div class="log__title">
        Call - Connected
        <div class="in-progress-badge">In progress</div>
      </div>
      <div class="log__date">{{ formatDate(callLog.date) }}</div>
    </div>
    <Textarea
      v-model="callLogStore.temporaryCallLogBody[contactId]"
      autoResize
      rows="2"
      variant="filled"
    />
  </div>
</template>

<script setup>
import { useCallLogStore } from '@/stores/callLogStore'
import { formatDate } from '../helpers/date'
import Textarea from 'primevue/textarea'
import { ref, watch, computed, nextTick, onMounted } from 'vue'

const callLogStore = useCallLogStore()

const props = defineProps({
  callLog: {
    type: Object,
    required: true
  },
  contactId: {
    type: Number,
    required: true
  }
})
</script>

<style scoped lang="scss">
.prospect__call-log {
  display: flex;
  gap: 16px;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 16px;
  &--active {
    border: 1px solid #14ba6d;
  }
  .log__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .log__title {
      font-size: 14px;
      font-weight: 500;
      display: flex;
    }
    .log__date {
      font-size: 12px;
      color: #667085;
    }
    .in-progress-badge {
      margin-left: 12px;
      display: flex;
      align-items: center;
      background-color: #14ba6d;
      color: #ffffff;
      border-radius: 4px;
      padding: 2px 4px;
      line-height: normal;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
</style>
