<template>
  <div :class="rowClass" class="row">
    <div class="row-content">
      <div class="icon-column">
        <div class="link-icon-container">
          <ExternalLinkIcon class="link-icon" size="18" />
        </div>
        <div
          class="row-menu"
          @click="($event) => $emit('menuClick', $event)"
          aria-haspopup="true"
          aria-controls="overlay_menu"
        >
          <ThreeDotIcon size="18" class="three-dot-icon" />
        </div>
      </div>
      <div @click="$emit('openModal')" class="cell cell--first-column clickable">
        <DialStatusBadge
          :status="row.dialStatus"
          :errorStatus="row.errorStatus"
          :meeting-booked="row.meetingBooked"
          :canceled-calls-count="row.canceledCallsCount"
          :tooltip="buildDialStatusTooltip"
        />
      </div>
      <div @click="$emit('openModal')" class="status-section cell clickable">
        <div class="call-count">
          <CallIcon size="16" class="call-icon" />
          {{ row.callCount }}
        </div>
      </div>
      <div
        v-for="(header, index) in filteredHeaders"
        :key="index"
        class="cell cell--fixed-width clickable"
        @click="$emit('openModal')"
      >
        <div v-if="header.id === 'status'">
          {{ formatLeadStatus(row[header.id]) }}
        </div>
        <div v-else-if="isUrl(row[header.id])" class="cell--link">
          <a @click.stop :href="row[header.id]" target="_blank">
            {{ row[header.id] }}
            <ExternalLinkIcon class="link-icon" size="14" />
          </a>
        </div>
        <div
          v-else-if="header.id === 'lastCall'"
          v-tooltip.top="{
            value: formatDateWithoutSeconds(row[header.id]),
            pt: {
              root: 'large-tooltip'
            }
          }"
          class="last-call"
        >
          {{ formatLastCallDate(row[header.id]) }}
        </div>
        <div v-else>
          {{ formatPotentialDate(row[header.id]) ?? '-' }}
        </div>
      </div>
    </div>
    <div :class="rowClass" class="transcription">
      <div class="transcription__text" v-if="row.transcription + row.tempTranscription">
        {{ row.transcription + row.tempTranscription ?? '' }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import CallIcon from './icons/CallIcon.vue'
import ExternalLinkIcon from './icons/ExternalLinkIcon.vue'
import ThreeDotIcon from './icons/ThreeDotIcon.vue'
import DialStatusBadge from './DialStatusBadge.vue'
import { isUrl } from '@/helpers/url'
import {
  formatPotentialDate,
  formatDateWithoutSeconds,
  formatDateWithoutTime
} from '@/helpers/date'
import { useContactStore } from '../stores/contactStore'
import { formatDistance } from 'date-fns'

const props = defineProps({
  row: {
    type: Object,
    required: true
  },
  headers: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['openModal', 'menuClick'])

const contactStore = useContactStore()

const filteredHeaders = computed(() =>
  props.headers.filter(
    (header) =>
      header.id !== 'dialStatus' &&
      header.id !== 'callCount' &&
      !header.hidden &&
      !header.hideInTable
  )
)

function formatLeadStatus(status) {
  if (!contactStore.leadStatuses) {
    return status
  }
  return contactStore.leadStatuses.find((item) => item.id === status)?.label
}

function formatLastCallDate(date) {
  if (!date) {
    return '-'
  }
  const now = new Date()
  return `${formatDistance(date, now)} ago`
}

const buildDialStatusTooltip = computed(() => {
  if (new Date(props.row.snoozeUntil) > new Date()) {
    return `Snoozed until ${formatDateWithoutTime(props.row.snoozeUntil)}`
  }

  if (props.row.doNotCall) {
    return `Calls to this contact are disabled`
  }
  return ''
})

const rowClass = computed(() => [
  {
    'row--default':
      props.row.dialStatus === 'pending' ||
      props.row.dialStatus === 'canceled' ||
      props.row.dialStatus === 'voicemail' ||
      props.row.dialStatus === 'busy' ||
      props.row.dialStatus === 'no-answer',
    'row--in-progress': props.row.dialStatus === 'in-progress',
    'row--ringing': props.row.dialStatus === 'ringing' || props.row.dialStatus === 'initiated',
    'row--completed':
      props.row.dialStatus === 'completed' ||
      props.row.dialStatus === 'connected' ||
      props.row.dialStatus === 'left-voicemail' ||
      props.row.dialStatus === 'failed' ||
      props.row.errorStatus === 'not-authorized' ||
      props.row.errorStatus === 'no number' ||
      props.row.errorStatus === 'invalid number' ||
      props.row.errorStatus === 'country mismatch' ||
      props.row.errorStatus === 'snoozed' ||
      props.row.errorStatus === 'disabled' ||
      props.row.errorStatus === 'max call',
    'row--meeting-booked':
      props.row.meetingBooked &&
      props.row.dialStatus !== 'in-progress' &&
      props.row.dialStatus !== 'ringing' &&
      props.row.dialStatus !== 'initiated'
  }
])
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  border-bottom: 1px solid #e2e8f0;
  transition: background 0.27s ease;

  &.row--ringing {
    background: #fdf8e1;
  }

  &.row--in-progress {
    background: #ecfdf3;
  }

  &.row--completed {
    background: #eef1f4;
  }

  &.row--meeting-booked {
    background: #f9eeff;
  }

  .row-content {
    display: flex;
    align-items: center;
  }
}

.cell {
  color: #667085;
  font-size: 14px;
  padding: 12px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-wrap: nowrap;

  &--fixed-width {
    min-width: 195px;
    max-width: 195px;
  }

  &--first-column {
    padding-left: 0;
    padding-right: 0;
    min-width: 145px;
  }

  &.clickable {
    cursor: pointer;
  }

  &:last-child {
    flex: 1;
  }

  &--link {
    a {
      display: flex;
      align-items: center;
      padding: 6px;
      border-radius: 4px;
      transition: 0.05s ease;

      .link-icon {
        margin-left: 6px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
      }
    }
  }
}

.icon-column {
  display: flex;
  justify-content: center;
  padding: 0 17px;
  color: #667085;
  font-size: 14px;
  vertical-align: middle;

  .link-icon {
    stroke: #787878;
    transition: all 1s ease;
  }

  .row-menu {
    transition: 0.05s ease;
    padding: 4px;
    display: flex;
    align-items: center;

    &:hover {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.07);
      cursor: pointer;
    }

    .three-dot-icon {
      fill: #787878;
    }
  }
}
.link-icon-container {
  padding: 4px;
  display: none;
  align-items: center;
  opacity: 0;
}
.row:hover .link-icon-container {
  opacity: 1;
  display: flex;
}

.row:hover .row-menu {
  display: none;
}

.row .icon-column:hover:first-child .row-menu {
  display: flex;
}

.row .icon-column:hover:first-child .link-icon-container {
  display: none;
}

.transcription {
  padding-left: 60px;
  font-size: 14px;
  font-weight: 500;
  color: #344054;

  &__text {
    padding-bottom: 8px;
    word-wrap: break-word;
  }
}

.call-count {
  color: #787878;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  .call-icon {
    stroke: #787878;
    margin-right: 6px;
  }
}

.status-section {
  display: flex;
  justify-content: space-between;
  min-width: 70px;
  max-width: 70px;
  .call-count {
    color: #787878;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    .call-icon {
      stroke: #787878;
      margin-right: 6px;
    }
  }
}
</style>
