<template>
  <Modal class="row-modal" :is-modal-open="isModalOpen" @closeModal="closeModal">
    <template v-if="rowDataCopy">
      <div class="modal__header">
        <div class="header-left">
          <div class="contact__name">
            <a :href="rowData.contactUrl" target="_blank"
              >{{ `${rowData.firstName} ${rowData.lastName}`
              }}<ExternalLinkIcon class="external-link-icon" size="16"
            /></a>
          </div>
          <div class="contact__title">
            {{ rowData.title }} at
            <span class="contact__title-link" @click="redirectTo(rowData.companyUrl)"
              >{{ `${rowData.company}` }}<ExternalLinkIcon class="external-link-icon" size="16"
            /></span>
          </div>
          <div v-if="rowData.email" class="contact__row">
            {{ rowData.email }}

            <div
              class="copy-icon-container"
              v-tooltip.top="'Copy email to clipboard'"
              @click="copyToClipboard(rowData.email)"
            >
              <CopyIcon class="copy-icon" size="20" />
            </div>
            <div v-if="copied" class="copy-message">Copied!</div>
          </div>
          <div v-if="rowData.linkedinUrl" class="contact__row">
            <a class="contact__title-link" :href="buildUrl(rowData.linkedinUrl)" target="_blank"
              >{{ `${rowData.linkedinUrl}` }}<ExternalLinkIcon class="external-link-icon" size="16"
            /></a>
          </div>
          <div v-if="rowData.companyDomain" class="contact__row">
            <a class="contact__title-link" :href="buildUrl(rowData.companyDomain)" target="_blank"
              >{{ `${rowData.companyDomain}`
              }}<ExternalLinkIcon class="external-link-icon" size="16"
            /></a>
          </div>
        </div>
        <div class="header-right" v-if="callable && sessionStore.fromPhoneNumber?.id">
          <div class="header__actions">
            <call-button
              :contact="props.rowData"
              @snoozed="($events) => emit('snoozed', $events)"
              @update:doNotCall="($events) => emit('update:doNotCall', $events)"
            />
          </div>
          <div v-if="new Date(props.rowData.snoozeUntil) > new Date()" class="snooze-until">
            Snoozed until: {{ formatDateWithoutTime(props.rowData.snoozeUntil) }}
          </div>
        </div>
      </div>
      <div
        class="modal__transcription"
        v-if="phoneStore.gg_activeCall.isActive && !phoneStore.isCallingFromModal"
      >
        <MicrophoneIcon class="microphone-icon" size="20" />
        {{
          props.rowData.transcription + props.rowData.tempTranscription
            ? `${props.rowData.transcription} ${props.rowData.tempTranscription}`
            : '...'
        }}
      </div>
      <div class="tabs">
        <div
          class="tab__section"
          :class="{ 'tab__section--active': currentTab.id === tab.id }"
          v-for="tab in tabs"
          :key="tab.label"
          @click="selectTab(tab)"
        >
          <div class="tab__button">
            {{ tab.label }}
          </div>
        </div>
      </div>
      <div class="modal__body" ref="modalBody">
        <prospect-activities
          v-if="currentTab.id === 'activities'"
          :activities="activities"
          :loading="rowData.activityLoading"
          :import-source="rowData.importSource"
          :prospect-name="prospectName"
          :contact-id="rowData.id"
          @save-note="saveNote"
          @delete-note="deleteNote"
          @pin-note="pinNote"
          @update-task="updateTask"
          @save-log="saveLog"
          @save-call-disposition="saveCallDisposition"
        />

        <prospect-info
          v-if="currentTab.id === 'info'"
          :data="rowDataWithHeaders"
          @save-new-value="saveNewValue"
          @update-custom-field="updateCustomField"
        />

        <prospect-notes
          v-if="currentTab.id === 'notes'"
          @create-new-note="createNewNote"
          @save-note="saveNote"
          @delete-note="deleteNote"
          @pin-note="pinNote"
          :import-source="rowData.importSource"
          :notes="rowData.notes"
          :loading="rowData.noteLoading || rowData.activityLoading"
        />

        <prospect-tasks
          v-if="currentTab.id === 'tasks'"
          @save-new-task="saveNewTask"
          :tasks="rowData.tasks"
          @update-task="updateTask"
          :loading="rowData.taskLoading || rowData.activityLoading"
          :import-source="rowData.importSource"
        />

        <prospect-emails
          v-if="currentTab.id === 'emails'"
          :emails="rowData.emails"
          :loading="rowData.emailLoading || rowData.activityLoading"
        />

        <prospect-call-logs
          v-if="currentTab.id === 'logs'"
          @save-log="saveLog"
          :call-logs="rowData.callLogs"
          :prospect-name="prospectName"
          @save-call-disposition="saveCallDisposition"
          :loading="rowData.callLogsLoading || rowData.activityLoading"
          :contact-id="rowData.id"
        />
      </div>

      <div class="modal__footer">
        <div class="footer__button">
          <Checkbox
            v-model="meetingBookedModel"
            @change="toggleMeetingBooked(props.rowData.id)"
            inputId="meetingBooked"
            name="meetingBooked"
            binary
          />
          <label class="button__text" for="meetingBooked"> Meeting booked </label>
        </div>
      </div>
    </template>
    <template v-else-if="loading">
      <div class="loader-container">
        <Loader></Loader>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import MicrophoneIcon from './icons/MicrophoneIcon.vue'
import CopyIcon from './icons/CopyIcon.vue'
import ExternalLinkIcon from './icons/ExternalLinkIcon.vue'
import Modal from './Modal.vue'
import Loader from './Loader.vue'
import ProspectTasks from './ProspectTasks.vue'
import ProspectEmails from './ProspectEmails.vue'
import ProspectNotes from './ProspectNotes.vue'
import ProspectInfo from './ProspectInfo.vue'
import ProspectActivities from './ProspectActivities.vue'
import ProspectCallLogs from './ProspectCallLogs.vue'
import Checkbox from 'primevue/checkbox'

import { ref, watch, computed } from 'vue'

import { usePhoneStore } from '@/stores/phone'
import { useSessionStore } from '@/stores/sessionStore'
import { formatDateWithoutTime, sortByDescendingDate } from '@/helpers/date'
import CallButton from './CallButton.vue'

const copied = ref(false)

function redirectTo(url) {
  if (!url) {
    return
  }
  window.open(url, '_blank')
}

const sessionStore = useSessionStore()

const prospectName = computed(() => `${props.rowData.firstName} ${props.rowData.lastName}`)

async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text)
    copied.value = true

    // Reset copied message after 2 seconds
    setTimeout(() => {
      copied.value = false
    }, 2000)
  } catch (err) {
    console.error('Failed to copy: ', err)
  }
}

function buildUrl(domain) {
  if (domain.startsWith('http')) {
    return domain
  }
  return 'https://' + domain
}

const phoneStore = usePhoneStore()

const modalBody = ref(null)

function selectTab(tab) {
  currentTab.value = tab
  modalBody.value.scrollTop = 0
}

const activities = computed(() => {
  return sortByDescendingDate([
    ...props.rowData.notes,
    ...props.rowData.tasks,
    ...props.rowData.emails,
    ...props.rowData.callLogs
  ])
})

const tabs = [
  { label: 'Activity', id: 'activities' },
  { label: 'Info', id: 'info' },
  { label: 'Notes', id: 'notes' },
  { label: 'Tasks', id: 'tasks' },
  { label: 'Emails', id: 'emails' },
  { label: 'Call logs', id: 'logs' }
]

const currentTab = ref()

resetTab()

function resetTab() {
  currentTab.value = tabs[0]
}

const props = defineProps({
  rowData: {
    type: Object,
    required: false
  },
  headers: {
    type: Array,
    required: true
  },
  isModalOpen: {
    type: Boolean,
    required: true
  },
  meetingBooked: {
    type: Boolean
  },
  callable: {
    type: Boolean,
    required: true
  },
  loading: {
    type: Boolean,
    required: false
  }
})

const meetingBookedModel = computed({
  get: () => props.meetingBooked,
  set: (value) => {}
})

const rowDataWithHeaders = computed(() => {
  return props.headers
    .filter((header) => header.id !== 'dialStatus' && !header.hideInModal && !header.hidden)
    .map((header) => {
      if (header.type === 'phone') {
        return {
          ...header,
          value: props.rowData?.phoneNumbers.find((phone) => phone.key === header.id)?.value || ''
        }
      }
      return {
        ...header,
        value: props.rowData ? props.rowData[header.id] : ''
      }
    })
})

async function toggleMeetingBooked(rowId) {
  emit('toggleMeetingBooked', rowId)
}

watch(
  () => props.rowData,
  (newValue) => {
    if (newValue) {
      rowDataCopy.value = JSON.parse(JSON.stringify(newValue))
      if (contactId.value !== newValue.id) {
        resetTab()
      }
      contactId.value = newValue.id
    } else {
      rowDataCopy.value = null
      contactId.value = null
    }
  }
)

const rowDataCopy = ref()
const contactId = ref()

function saveNewValue(header, value) {
  emit('saveNewInfo', props.rowData, header, value)
}

function updateCustomField({ field, value }) {
  emit('updateCustomField', props.rowData, { field, value })
}

function saveNote(noteId, body) {
  emit('saveNote', props.rowData.id, noteId, body)
}

function deleteNote(noteId) {
  emit('deleteNote', props.rowData.id, noteId)
}

function pinNote(noteId) {
  emit('pinNote', props.rowData.id, noteId)
}

function saveLog(logId, body) {
  emit('saveLog', props.rowData.id, logId, body)
}

function saveCallDisposition(logId, callDisposition) {
  emit('saveCallDisposition', props.rowData.id, logId, callDisposition)
}

async function createNewNote() {
  emit('createNewNote', props.rowData.id)
}

async function saveNewTask(task) {
  emit('saveNewTask', props.rowData.id, task)
}

async function updateTask(taskId, taskField, taskValue) {
  emit('updateTask', props.rowData, taskId, taskField, taskValue)
}

const emit = defineEmits([
  'closeModal',
  'saveNewInfo',
  'saveNote',
  'deleteNote',
  'pinNote',
  'saveLog',
  'saveCallDisposition',
  'createNewNote',
  'saveNewTask',
  'updateTask',
  'toggleMeetingBooked',
  'updateCustomField',
  'snoozed',
  'update:doNotCall'
])

function closeModal() {
  emit('closeModal')
}
</script>

<style lang="scss" scoped>
.row-modal {
  .loader-container {
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }
  .modal__body {
    padding: 18px 48px;
    flex: 1;
    overflow-y: auto;
  }

  .modal__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eaecf0;
    padding: 38px 48px 12px 48px;
  }

  .modal__transcription {
    margin-right: 48px;
    margin-left: 48px;
    margin-top: 18px;
    border: 1px solid #14ba6d;
    background-color: #ecfdf3;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    align-items: center;
    font-weight: 500;

    .microphone-icon {
      stroke: #344054;
      margin-right: 4px;
    }
  }

  .header-left {
    font-weight: 500;
    color: #37352f;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 6px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    .contact__name {
      display: flex;
      font-size: 20px;
    }

    .contact__title {
      font-size: 16px;
      padding-left: 6px;

      width: 100%;
      flex-wrap: wrap;
      white-space: normal;

      .contact__title-link {
        color: #787878;
        margin-left: 2px;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 4px;
        transition: 0.05s ease;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.07);
        }

        .external-link-icon {
          margin-bottom: 3px;
          vertical-align: middle;
        }
      }
    }

    .contact__row {
      display: flex;
      font-size: 14px;
      padding-left: 6px;
      align-items: center;

      .copy-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;
        padding: 2px;
        border-radius: 4px;
        transition: background 0.02s ease;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.07);
        }
      }
      .copy-message {
        color: #14ba6d;
        font-size: 0.9em;
        margin-left: 8px;
        align-self: end;
      }
      .copy-icon {
        stroke: currentColor;
      }
    }

    .contact__row:has(a) {
      padding-left: 0;
    }

    a {
      display: flex;
      align-items: center;
      padding-left: 6px;
      padding-right: 6px;
      border-radius: 4px;
      transition: 0.05s ease;
    }

    .external-link-icon {
      margin-left: 6px;
      stroke: currentColor;
    }
  }

  .header-right {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .header__actions {
      flex-shrink: 0;
      display: flex;
      align-self: end;
      align-items: start;
      gap: 8px;
    }
    .snooze-until {
      font-weight: 500;
    }
  }

  .modal__footer {
    border-top: 1px solid #eaecf0;
    padding: 16px 50px;
    display: flex;
  }

  .footer__button {
    background-color: rgba(0, 123, 255, 0.1);
    border-radius: 8px;
    color: #0075f2;
    border: none;
    padding: 10px 16px;
    display: flex;
    align-items: center;
  }

  .footer__button:hover {
    cursor: pointer;
    background-color: rgba(0, 123, 255, 0.15);
  }

  /* Checkbox styles */
  .footer__button input {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #d2d2d2;
  }

  .button__text {
    margin-left: 8px;
    font-weight: 500;
    user-select: none;

    &:hover {
      cursor: pointer;
    }
  }

  .modal__body-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .item__title {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .table__headers {
    font-size: 12px;
    font-weight: 500;
    color: #667085;
    display: flex;
    border-bottom: 1px solid #eaecf0;
    border-top: 1px solid #eaecf0;
    padding: 8px;
    background: #fcfcfd;
  }

  .table__header,
  .table__cell {
    flex: 1;
  }

  .table__row {
    font-size: 12px;
    color: #667085;
    display: flex;
    border-bottom: 1px solid #eaecf0;
    padding: 8px;
  }
  .table__cell {
    /* flex: 0 0 20%; */
    flex: 1;
    overflow: auto;
  }
  .table__loader {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    color: rgba(55, 53, 47, 0.65);
    font-size: 13px;
  }

  .tabs {
    display: flex;
    border-bottom: 1px solid #eaecf0;
    color: #828282;
    font-size: 14px;
    user-select: none;
    margin-right: 48px;
    margin-left: 48px;
    margin-top: 18px;

    .tab__section {
      padding-bottom: 4px;
      font-weight: 500;
      .tab__button {
        padding-left: 16px;
        padding-right: 16px;
        transition: 0.05s ease;
        &:hover {
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.07);
          opacity: 1;
          cursor: pointer;
        }
      }
      &--active {
        border-bottom: 2px solid #212223;
        color: #212223;
      }
    }
  }
}
</style>
