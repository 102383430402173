import { defineStore } from 'pinia'
import axios from '../services/axiosService'
import { useVoicemailStore } from './voicemail'
import { usePhoneStore } from './phone'
import { assertIsPhoneNumberAuthorized, assertIsPhoneNumberValid } from '@/helpers/phoneNumber'
import { fetchNotes } from '@/services/fetchNotes'
import { fetchCallLogs } from '@/services/fetchCallLogs'
import { fetchTasks } from '@/services/fetchTasks'
import { createNewNote } from '@/services/createNewNote'
import { saveNewTask } from '@/services/saveNewTask'
import { updateField } from '@/services/updateField'
import { updateNote } from '@/services/updateNote'
import { updateLog } from '@/services/updateLog'
import { updateCallDisposition } from '@/services/updateCallDisposition'
import { updateTask } from '@/services/updateTask'
import { fetchActivities } from '@/services/fetchActivities'
import { updatePhoneNumberField } from '@/services/updatePhoneNumberField'
import { deleteNote } from '@/services/deleteNote'
import { pinNote } from '@/services/pinNote'
import { useContactStore } from './contactStore'
import { updateCustomField } from '@/services/updateCustomField'

export const useSessionStore = defineStore('session', {
  state: () => ({
    currentSessionId: null,
    error: null,
    fromPhoneNumber: { phoneNumber: '+33612345678' },
    fromPhoneNumberList: [],
    callInParallel: 1,
    sessionName: '',
    lastRefresh: '',
    refreshable: false,
    sessionListId: null,
    taskList: false,
    sessionImportSource: null,
    sessions: {},
    selectedRowId: null,
    sessionList: [],
    errorMessage: '',
    completeTaskWhenDialed: false,
    rotateFromPhoneNumber: true,
    maxCallAttemptLimitList: [
      { id: null, label: 'Unlimited' },
      { id: 1, label: '1' },
      { id: 2, label: '2' },
      { id: 3, label: '3' },
      { id: 4, label: '4' },
      { id: 5, label: '5' },
      { id: 6, label: '6' },
      { id: 7, label: '7' },
      { id: 8, label: '8' },
      { id: 9, label: '9' },
      { id: 10, label: '10' }
    ],
    maxCallAttemptPeriodList: [
      { id: 'day', label: 'day' },
      { id: 'week', label: 'week' },
      { id: 'month', label: 'month' }
    ],
    maxCallAttempts: null,
    callAttemptPeriod: null,
    conversationThreshold: 30,
    tempSessionName: '',
    phoneNumberList: [],
    phoneNumberFieldSelected: null,
    rotationCallCount: 0,
    rotationThreshold: 10,
    transcribeCalls: false
  }),
  getters: {
    getFromNumber: (state) => state.fromPhoneNumber?.phoneNumber,
    getMeetingBooked: (state) => (sessionId, contactId) =>
      !!state.sessions[sessionId]?.prospects.find((item) => item.id === contactId)?.meetingBooked,
    isUserPhoneNumber: (state) => (phoneNumber) =>
      state.fromPhoneNumberList.find((phone) => phone.phoneNumber === phoneNumber)
  },
  actions: {
    incrementCallCount() {
      if (!this.rotateFromPhoneNumber) {
        return
      }
      if (this.rotationCallCount >= this.rotationThreshold) {
        this.rotatePhoneNumber()
      }
      this.rotationCallCount += 1
    },
    rotatePhoneNumber() {
      const currentCountry = this.fromPhoneNumber.country
      const countrySpecificNumbers = this.fromPhoneNumberList.filter(
        (num) => num.country === currentCountry
      )

      const currentIndex = countrySpecificNumbers.findIndex(
        (num) => num.phoneNumber === this.fromPhoneNumber.phoneNumber
      )

      const nextIndex = (currentIndex + 1) % countrySpecificNumbers.length
      this.fromPhoneNumber = countrySpecificNumbers[nextIndex]
      this.rotationCallCount = 0
    },
    async updateConversationThreshold(conversationThreshold) {
      const oldValue = this.conversationThreshold
      try {
        this.conversationThreshold = conversationThreshold

        await axios.put('/api/user/settings', {
          conversationThreshold
        })

        console.log(`Settings conversation threshold updated successfully`)
      } catch (error) {
        console.error('Error while updating settings', error)
        this.errorMessage = 'Could not update settings'
        this.conversationThreshold = oldValue
      }
    },
    async updateSetting(key, value) {
      try {
        this[key] = value

        await axios.put('/api/user/settings', {
          [key]: value
        })

        console.log(`Setting ${key} updated successfully`)
        if (key === 'maxCallAttempts' || key === 'callAttemptPeriod') {
          const phoneStore = usePhoneStore()
          try {
            phoneStore.contactLoading = true
            await phoneStore.initContactList()
          } catch (error) {
            throw error
          } finally {
            phoneStore.contactLoading = false
          }
        }
      } catch (error) {
        console.error('Error while updating setting', error)
        this.errorMessage = 'Could not update setting'
        this[key] = !value
      }
    },
    async updatePhoneNumberFieldSetting(value, integrationName) {
      const oldValue = this.phoneNumberFieldSelected
      try {
        this.phoneNumberFieldSelected = value

        await axios.put('/api/user/settings/phone-number-field', {
          phoneNumberField: value,
          integrationName
        })

        console.log(`Setting phoneNumberField updated successfully`)
      } catch (error) {
        console.error('Error while updating setting', error)
        this.errorMessage = 'Could not update setting'
        this.phoneNumberFieldSelected = oldValue
      }
    },
    setUserSetting(userSetting) {
      this.completeTaskWhenDialed = userSetting.completeTaskWhenDialed
      this.rotateFromPhoneNumber = userSetting.rotateFromPhoneNumber
      this.conversationThreshold = userSetting.conversationThreshold
      this.maxCallAttempts = userSetting.maxCallAttempts
      this.callAttemptPeriod = userSetting.callAttemptPeriod
      this.transcribeCalls = userSetting.transcribeCalls
    },
    setSessions(sessions) {
      this.sessionList = sessions
    },
    async fetchPhoneNumbers() {
      try {
        const { data } = await axios.get(`/api/user/phone-numbers`)
        this.fromPhoneNumberList = data.phoneNumbers
        if (!this.currentSessionId) {
          this.fromPhoneNumber = this.fromPhoneNumberList[0]
        }
      } catch (error) {
        console.error('Error fetching phone numbers:', error)
      }
    },
    reset() {
      this.$reset()
    },
    checkActiveSession(activeSession) {
      this.currentSessionId = activeSession ? activeSession.session_id : null
    },
    setSessionId(sessionId) {
      this.currentSessionId = sessionId
    },
    async initCurrentSessionSetting() {
      try {
        if (!this.currentSessionId) {
          return
        }

        const { data } = await axios.get(`/api/sessions/${this.currentSessionId}`)

        this.fromPhoneNumber = data.session.fromPhoneNumber
        this.callInParallel = data.session.parallelCalls
        this.sessionName = data.session.sessionName
        this.lastRefresh = data.session.lastRefresh
        this.refreshable = data.session.refreshable
        this.sessionListId = data.session.sessionListId
        this.taskList = data.session.taskList
        this.sessionImportSource = data.session.importSource
        const voicemailStore = useVoicemailStore()
        voicemailStore.setVoicemail(data.session.voicemail)
      } catch (error) {
        console.error('error initializing session settings', error)
      }
    },
    async initSessionSetting(sessionId) {
      try {
        const { data } = await axios.get(`/api/sessions/${sessionId}`)

        if (!this.sessions[sessionId]) {
          this.sessions[sessionId] = {}
        }

        this.sessions[sessionId].fromPhoneNumber = data.session.fromPhoneNumber
        this.sessions[sessionId].callInParallel = data.session.parallelCalls
        this.sessions[sessionId].sessionName = data.session.sessionName
        this.sessions[sessionId].lastRefresh = data.session.lastRefresh
        this.sessions[sessionId].refreshable = data.session.refreshable
        this.sessions[sessionId].sessionListId = data.session.sessionListId
        this.sessions[sessionId].taskList = data.session.taskList
        this.sessions[sessionId].sessionImportSource = data.session.sessionImportSource
        this.sessions[sessionId].voicemail = data.session.voicemail
      } catch (error) {
        console.error('error initializing session settings', error)
      }
    },
    async updateSessionSettings({ sessionName, fromPhoneNumberId, audioMessageId, parallelCalls }) {
      if (!this.currentSessionId) {
        return
      }

      try {
        await axios.put(`/api/sessions/${this.currentSessionId}/settings`, {
          sessionName,
          fromPhoneNumberId,
          audioMessageId,
          parallelCalls
        })
        if (fromPhoneNumberId) {
          const phoneStore = usePhoneStore()
          phoneStore.computeNumberStatuses()
        }
      } catch (error) {
        console.error('error updating session settings', error)
      }
    },
    async deleteContact(sessionId, contact) {
      const index = this.sessions[sessionId].prospects.findIndex((item) => item.id === contact.id)
      try {
        this.sessions[sessionId].prospects.splice(index, 1)

        const { data } = await axios.delete(`/api/sessions/${sessionId}/prospects/${contact.id}`)
        console.log('Contact successfully deleted', data)
      } catch (error) {
        console.error('Error deleting contact:', error)
        this.sessions[sessionId].prospects.splice(index, 0, contact)
        this.errorMessage = error?.response?.data?.error || 'Could not delete the contact'
      }
    },
    async endSession() {
      if (!this.currentSessionId) {
        console.error('No active session to end')
        return
      }

      try {
        await axios.post(`/api/sessions/${this.currentSessionId}/end`)
        this.error = null
      } catch (error) {
        this.error = error.response?.data?.error || 'Failed to end session'
        console.error('Error ending session:', error)
      }
    },
    resetSession() {
      this.currentSessionId = null
      this.sessionName = ''
      this.lastRefresh = null
      this.refreshable = false
      this.sessionListId = null
      this.taskList = false
      this.sessionImportSource = false
    },
    async updateField(sessionId, contact, { id, label, type }, value) {
      try {
        if (id === 'phoneNumber' || type === 'phone') {
          await updatePhoneNumberField(contact, id, value)
          this.computeNumberStatuses(this.sessions[sessionId].prospects)
        } else {
          await updateField(contact, id, value)
        }
      } catch (error) {
        this.errorMessage = `Error updating prospect: ${label.toLowerCase()}`
      }
    },

    async updateCustomField(sessionId, contactId, { field, value }) {
      const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
      updateCustomField(contact, { field, value })
    },
    async openRowModal(sessionId, row) {
      this.selectedRowId = row.id

      this.fetchActivities(sessionId, row.id)
    },
    async fetchActivities(sessionId, contactId) {
      const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
      await fetchActivities(contact)
    },
    async fetchNotes(sessionId, contactId) {
      const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
      await fetchNotes(contact)
    },
    async fetchTasks(sessionId, contactId) {
      const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
      await fetchTasks(contact)
    },
    async updateTask(sessionId, contactId, taskId, taskField, taskValue) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await updateTask(contact, taskId, taskField, taskValue)
      } catch (error) {
        console.log('error updating task', error)
        this.errorMessage = `Error updating task`
      }
    },
    async toggleMeetingBooked(sessionId, contactId) {
      try {
        const currentValue = this.sessions[sessionId].prospects.find(
          (item) => item.id === contactId
        ).meetingBooked
        await axios.post(`/api/prospects/${contactId}/meeting-booked`, {
          meetingBooked: !currentValue,
          sessionId: sessionId
        })
        console.log('Meeting booked status updated')

        this.sessions[sessionId].prospects.find((item) => item.id === contactId).meetingBooked =
          !this.sessions[sessionId].prospects.find((item) => item.id === contactId).meetingBooked
      } catch (error) {
        console.error('Error updating meeting booked status:', error)
        // Handle error (e.g., show error message)
      }
    },
    async fetchCallLogs(sessionId, contactId) {
      const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
      await fetchCallLogs(contact)
    },
    async updateLog(sessionId, contactId, logId, body) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await updateLog(contact, logId, body)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating log`
      }
    },
    async updateCallDisposition(sessionId, contactId, logId, callDisposition) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await updateCallDisposition(contact, logId, callDisposition)
        await fetchCallLogs(contact)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating call disposition`
      }
    },
    async saveNewTask(contactId, task) {
      try {
        await saveNewTask(contactId, task)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error creating task`
      }
    },
    async updateNote(sessionId, contactId, noteId, body) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await updateNote(contact, noteId, body)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating note`
      }
    },
    async deleteNote(sessionId, contactId, noteId) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await deleteNote(contact, noteId)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error deleting note`
      }
    },
    async pinNote(sessionId, contactId, noteId) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await pinNote(contact, noteId)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error deleting note`
      }
    },
    async createNewNote(contactId) {
      try {
        await createNewNote(contactId)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error creating note`
      }
    },
    async fetchSessionProspectList(sessionId) {
      if (!sessionId) {
        console.error('No session ID')
        return
      }

      try {
        const { data } = await axios.get(`/api/sessions/${sessionId}/prospects`)

        if (!this.sessions[sessionId]) {
          this.sessions[sessionId] = {}
        }

        const phoneStore = usePhoneStore()

        await phoneStore.fetchParameters(data.prospects[0].importSource)

        this.sessions[sessionId].headers = phoneStore.buildHeaders(
          data.prospects[0],
          data.customFields,
          this.phoneNumberList
        )

        // You can process the data here as needed before saving it to state
        this.sessions[sessionId].prospects = data.prospects
        this.computeNumberStatuses(data.prospects)
      } catch (error) {
        console.error('Error fetching session prospects:', error)
        this.error = error
      }
    },
    computeNumberStatuses(contacts) {
      for (const contact of contacts) {
        if (!contact.phoneNumber) {
          contact.errorStatus = 'no number'
          contact.wrongNumber = true
          continue
        }
        try {
          assertIsPhoneNumberValid(contact.phoneNumber)
        } catch (error) {
          contact.errorStatus = 'invalid number'
          contact.wrongNumber = true
          continue
        }

        try {
          const phoneStore = usePhoneStore()
          assertIsPhoneNumberAuthorized(contact.phoneNumber, phoneStore.authorizedCountryCodes)
        } catch (error) {
          contact.errorStatus = 'not-authorized'
          contact.wrongNumber = true
          continue
        }

        contact.errorStatus = ''
        contact.wrongNumber = false
      }
      const dialStatusOrder = ['country mismatch', 'not-authorized', 'invalid number', 'no number']

      contacts = contacts.sort((contactA, contactB) => {
        if (contactA.errorStatus && contactB.errorStatus) {
          const orderA = dialStatusOrder.indexOf(contactA.errorStatus)
          const orderB = dialStatusOrder.indexOf(contactB.errorStatus)
          return orderA - orderB
        }
        if (contactA.errorStatus) {
          return 1
        }
        if (contactB.errorStatus) {
          return -1
        }
        return 0
      })
    }
  }
})
