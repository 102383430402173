<template>
  <div class="setting__container">
    <div class="setting__title">Field mapping</div>

    <div class="setting__subtitle">
      <InfoIcon class="trash-icon" size="20" />Refresh the list to see new mapping
    </div>

    <div class="setting__body">
      <div v-if="mappedFieldsStore.loading && !mappedFieldsStore.resyncLoading" class="loader">
        <Loader :size="20"></Loader>
      </div>
      <div
        v-else
        v-for="field in mappedFieldsStore.mappedFields"
        :key="field.value"
        class="setting__item"
      >
        <div class="setting__item-text">
          {{ field.label }}
        </div>
        <SelectDropdown
          alignRight
          :items="mappedFieldsStore.crmFields"
          :initialValue="field"
          @update:value="($events) => onValueUpdate(field, $events)"
        >
          <template #button="{ toggleDropdown, isOpen }">
            <div class="dropdown__button" @click="toggleDropdown">
              <div>
                {{ mappedFieldsStore.crmFields.find((item) => item.value === field.value)?.label }}
              </div>
              <ChevronDownIcon class="dropdown__button-svg" v-if="!isOpen" size="12" />
              <ChevronUpIcon class="dropdown__button-svg" v-else color="#D0D5DD" size="12" />
            </div>
          </template>
        </SelectDropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useMappedFieldsStore } from '@/stores/mappedFieldsStore'
import InfoIcon from '../icons/InfoIcon.vue'
import ChevronDownIcon from '../icons/ChevronDownIcon.vue'
import ChevronUpIcon from '../icons/ChevronUpIcon.vue'
import SelectDropdown from '../SelectDropdown.vue'
import Loader from '../Loader.vue'

async function onValueUpdate(field, mappedField) {
  await mappedFieldsStore.updateMappedField(field, mappedField)
}

const mappedFieldsStore = useMappedFieldsStore()

onMounted(async () => {
  mappedFieldsStore.fetchMappedFields()
})
</script>

<style scoped lang="scss">
.setting__container {
  padding: 36px 60px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  .setting__title {
    color: #212223;
    font-size: 18px;
    font-weight: 500;
    display: flex;
  }

  .setting__subtitle {
    display: flex;
    align-items: center;
    color: #667085;
    font-weight: 500;
  }

  .setting__body {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    flex: 1;
    margin-top: 16px;

    .loader {
      align-self: center;
    }

    .setting__item {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: 6px;
      padding: 8px 0;
      border-bottom: 1px solid rgba(55, 53, 47, 0.09);
      &:last-child {
        border: none;
      }
    }
  }

  .dropdown__button {
    padding: 4px 16px 4px 8px;
    transition: 0.05s ease;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-weight: 500;

    color: #344054;

    padding: 4px 16px;
    font-weight: 500;
    border-radius: 8px;

    min-width: 80px;
    min-height: 32px;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    .dropdown__button-svg {
      stroke: #d0d5dd;
    }
  }
}
</style>
